<template>
    <complated />
</template>

<script>
// @ is an alias to /src
import complated from "@/components/apply-complated";
export default {
  name: "apply_complated",
  components: {
    complated
  }
};
</script>